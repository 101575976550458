const checkDuplicate = (filteredServices, newService) => {
  const check = filteredServices.find((service) => service.node.title === newService.node.title);
  return check;
};

export const filterByCategory = (selectedCategories = [], services) => {
  if (selectedCategories.length < 1) {
    return services;
  }

  const filterCategories = [...selectedCategories];
  const filteredServices = [];

  while (filterCategories.length !== 0) {
    const category = filterCategories.pop();
    services.map((service) => {
      const serviceCategories = service.node.categories.map((cat) => cat = cat.title);
      if (!serviceCategories) {
        return null;
      } if (serviceCategories.includes(category) && checkDuplicate(filteredServices, service) === undefined) {
        return filteredServices.push(service);
      }
      return null;
    });
  }

  return filteredServices;
};
