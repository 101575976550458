// extracted by mini-css-extract-plugin
export var BgImgContainer = "Services-module--BgImgContainer--f2405";
export var Button = "Services-module--Button--694d5";
export var CatCol = "Services-module--CatCol--a39c6";
export var Label = "Services-module--Label--657b8";
export var Loader = "Services-module--Loader--b6a9b";
export var SearchCol = "Services-module--SearchCol--59394";
export var SearchRow = "Services-module--SearchRow--f447c";
export var SearchServices = "Services-module--SearchServices--f1b5d";
export var ServicesWrapper = "Services-module--ServicesWrapper--f9a0e";
export var Title = "Services-module--Title--faaa0";
export var TitleContainer = "Services-module--TitleContainer--746ce";
export var Wrapper = "Services-module--Wrapper--1f49a";