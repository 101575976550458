import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { Box, Button, Card, Flex, CardBody, CardFooter, Heading, Link, Text } from '@chakra-ui/react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { ArrowForwardIcon } from '@chakra-ui/icons';

function ServiceInfo(props) {
  return (
    <Box>
        <Link as={GatsbyLink} to={`/services/${props.slug}`}>
          <Card _hover={{
          '& img': {
            filter: 'unset'
          }
        }} css={{
          borderRadius: '4px',
          '& img': {
            filter: 'brightness(0.7)'
          }
        }} color='pink.200'>
          <GatsbyImage 
            alt={props.tile}
            image={props?.mainImg?.gatsbyImageData}
          />
            <CardBody>
                  <Heading size='larger' color="gray.800" as='h2'>{props.title}</Heading>
                  <Text css={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    '-webkit-line-clamp': '3',
                    '-webkit-box-orient': 'vertical',
                  }} color='gray.700'>{props.description}</Text>
                  <Flex flexWrap='wrap' pt={4}>
                  {props?.categories && props?.categories.length && props?.categories?.length > 0 && props.categories.map((cat) => (
                    <Text mt={2} fontSize='smaller' fontWeight={500} textTransform='uppercase' background='brand.50' color='brand.300' mr={2} py={1} px={2} borderRadius='3px' as='span' key={cat?.title}>{cat?.title}</Text>
                  ))}
                  </Flex>
            </CardBody>
            <CardFooter>
              <Button rightIcon={<ArrowForwardIcon mt={1} mr={1} color={"laser.500"} />
} variant='link'>Read More</Button>
            </CardFooter>
          </Card>
        </Link>
    </Box>
  );
}

export default ServiceInfo;
