import React from "react"
import * as classes from "./BgImg.module.css"
import { Heading } from "@chakra-ui/react"

const BgImg = props => (
  <div className="row">
    <div className="col px-0">
      <Heading
        size={{ base: "2xl", md: "4xl" }}
        css={{
          position: "absolute",
          zIndex: 1,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: 200,
          letterSpacing: "10px",
          fontFamily: "'Oswald', sans-serif",
        }}
        color="whiteAlpha.900"
        as="h1"
      >
        {props.title}
      </Heading>

      <div
        style={{ backgroundImage: `url(${props?.image})`, ...props?.styling }}
        className={`${classes.Picture}`}
      ></div>
    </div>
  </div>
)

export default BgImg
