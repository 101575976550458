import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import Seo from "../components/gatsby-starter/seo"
import Services from "../components/Services/Services/Services"

function ServicesPage(props) {
  return (
    <Layout logo={props?.data?.sanityHomePage?.logo?.asset} tagline={props?.data?.sanityHomePage?.tagline}>
      <Seo
        title="Services"
        description="View all of the services that we offer. Search and learn more about our laser, face, skin, and spa services."
      />
      <Services {...props} data={props.data} />
    </Layout>
  )
}

export const query = graphql`
  {
    sanityHomePage {
      tagline
      logo {
        asset {
          gatsbyImageData(placeholder: BLURRED, formats: AUTO, width: 300)
        }
      }
    }
    allSanityHomePage {
      edges {
        node {
          servicesPageHeader {
            asset {
              id
              url
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
    allSanityService(sort: {fields: rank, order: ASC})  {
      edges {
        node {
          id
          mainImage {
            asset {
              id
              url
              gatsbyImageData(
                placeholder: BLURRED
                height: 400
                width: 400
                fit: CROP
                formats: [AUTO, JPG]
              )
            }
          }
          slug {
            current
          }
          showRelated
          descriptionShort
          title
          categories {
            title
          }
        }
      }
    }
    allSanityCategory {
      nodes {
        title
      }
    }
  }
`

export default ServicesPage
