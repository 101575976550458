import React, { useState } from "react"
import * as classes from "./Services.module.css"
import ServicesList from "../ServicesList/ServicesList"
import BgImg from "../../BgImg/BgImg"

function Services({ data, location }) {
  const { state = {} } = location
  const { cats = [] } = state || { cats: [] }

  const [searchServices, setSearchServices] = useState("")
  const [selectedCategories, setSelectedCategories] = useState(cats)

  function onChangeCategory(id) {
    const newCategories = [...selectedCategories]

    if (newCategories.includes(id)) {
      return setSelectedCategories(newCategories.filter(cat => cat !== id))
    }
    newCategories.push(id)

    setSelectedCategories(newCategories)
  }

  return (
    <div className={`${classes.Wrapper} container-fluid`}>
      {data?.allSanityHomePage?.edges[0]?.node?.servicesPageHeader?.asset
        ?.url && (
          <div className={classes.BgImgContainer}>
            <BgImg
              title="Services"
              styling={{
                filter: "brightness(0.6)",
              }}
              image={
                data?.allSanityHomePage?.edges[0]?.node?.servicesPageHeader?.asset
                  ?.url
              }
            />
          </div>
      )}
      <div style={{ background: "#eee" }} className="row d-flex d-xs-block">
        <div className={`${classes.ServicesWrapper} col d-flex`}>
          <ServicesList
            selectedCategories={selectedCategories}
            services={data?.allSanityService?.edges}
            title={searchServices}
          />
        </div>
      </div>
    </div>
  )
}

export default Services
