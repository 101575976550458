import React from "react"
import ServiceInfo from "../ServiceInfo/ServiceInfo"
import * as classes from "./ServicesList.module.css"
import { filterByCategory } from "../../../util/search"
import ContactForm from "../../Forms/ContactForm/ContactForm"
import { Flex, Grid } from "@chakra-ui/react"

function ServicesList(props) {
  let services = props?.services?.filter(service => {
    if (service?.node?.title) {
      return service?.node?.title?.indexOf(props.title) !== -1
    }
    return false
  })

  if (props?.selectedCategories?.length > 0) {
    services = filterByCategory(props.selectedCategories, services)
  }

  return (
    <Grid pt='10vh' gridTemplateColumns={{
      base: '1fr',
      sm: 'repeat(2, minmax(200px, 400px))',
      lg: 'repeat(3, minmax(200px, 400px))'
    }}
    gridGap='20px'>
      {services.map((service, i) => (
        <ServiceInfo
          key={i}
          categories={service?.node?.categories}
          title={service?.node?.title}
          description={service?.node?.descriptionShort}
          mainImg={
            service?.node?.mainImage ? service?.node?.mainImage?.asset : ""
          }
          slug={service?.node?.slug ? service?.node?.slug?.current : ""}
        />
      ))}
      {services.length === 0 && !props.loading ? (
        <div className={classes.Oops}>
          <p>
            <strong>Oops, no results!</strong>
          </p>
          <h3>Couldn&apos;t find what you were looking for?</h3>
          <ContactForm
            styling={{
              marginRight: "auto",
              marginLeft: "auto",
              width: "300px",
            }}
          />
        </div>
      ) : null}
    </Grid>
  )
}

export default ServicesList
